import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  SIGNOUT_ROLE_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,USER_ROLE_SET,
  SHOW_MESSAGE,
} from "../../constants/ActionTypes";
import axios from "util/Api";
import {notification} from "antd";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const userSignUp = ({ email, password, name }) => {
  // // console.log(email, password);
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("registration_seller/", {
        email: email,
        password: password,
        first_name: name,
      })
      .then(({ data }) => {
        // // console.log("data:", data);
        if (data.result) {
          localStorage.setItem(
            "token",
            JSON.stringify(data.token.access_token)
          );
          axios.defaults.headers.common["authorization"] =
            "Bearer " + data.token.access_token;
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_TOKEN_SET, payload: data.token.access_token });
          dispatch({ type: USER_DATA, payload: data.user });
        } else {
          // // console.log("payload: data.error", data.error);
          dispatch({ type: FETCH_ERROR, payload: "Network Error" });
        }
      })
      .catch(function (error) {
        const str = error.request.responseText;
        const regex = /-|\[|\]|\^|'|\*\.|>|<|\~|@|#|\$|%|\+|=\?|\||\\|\\\\|\)|\(/g;
        const regex2 = /-|\"|\"|\^|'|\*\.|>|<|\~|@|#|\$|%|\+|=\?|\||\\|\\\\|\)|\(/g;
        const regex3 = /-|\,|\|\^|'|\*\.|>|<|\~|@|#|\$|%|\+|=\?|\||\\|\\\\|\)|\(/g;
        const regex4 = /-|\{|\}|\^|'|\*\.|>|<|\~|@|#|\$|%|\+|=\?|\||\\|\\\\|\)|\(/g;
        const regex5 = /-|\:|\|\^|'|\*\.|>|<|\~|@|#|\$|%|\+|=\?|\||\\|\\\\|\)|\(/g;

        const regex6 = /([a-zA-Z0-9]+(?:_[a-zA-Z0-9]+)*:)/g;

        const rep1 = str.replace(regex, "");
        const rep2 = rep1.replace(regex2, "");
        const rep3 = rep2.replace(regex4, "");
        const rep4 = rep3.replace(regex3, "\n");
        const rep5 = rep4.replace(regex5, " : ");
        alert(rep3);
      });
  };
};

export const userSignIn = ({ username, password }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("api/login/", {
        username: username,
        password: password,
      })
      .then(({ data }) => {
        // // console.log("userSignIn: ", data);
        if (data.result) {
          localStorage.setItem(
            "token",
            JSON.stringify(data.token.access_token)
          );
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + data.token.access_token;

          axios
          .get(`/api/get_user_details/?username=`+localStorage.getItem("username"), {
            headers: { Authorization: `Token `+JSON.parse(localStorage.getItem("token"))  },
          })
          .then((res1) => {

            //CHECK IF DEFAULT PASSWORD
            axios.get(`api/check_default_password/?role=${ res1.data.role}&username=`+localStorage.getItem("username"), {
              headers: { Authorization: `Token `+JSON.parse(localStorage.getItem("token"))  },
            })
            .then((res2) => {
              if(res2.data.message == true){
                dispatch({
                  type: FETCH_ERROR,
                  payload: res2.data.message,
                });
              }
              else{
                
                  localStorage.setItem("name", res1.data.first_name+" "+res1.data.last_name); 
                  
                  if(res1.data.role === 5){
                    axios
                    .get(`/api/get_outlet_type_by_user_id/?user=${res1.data.id}`, {
                      headers: { Authorization: `Token `+JSON.parse(localStorage.getItem("token"))  },
                    })
                    .then((res) => {
                      localStorage.setItem("type", res.data.type); 
                      
                    });
                  }
                  
                  
                  localStorage.setItem("role", res1.data.role); 
                  dispatch({ type: USER_ROLE_SET, payload: res1.data.role });
                  dispatch({ type: USER_TOKEN_SET, payload: data.token.access_token });
                  dispatch({ type: FETCH_SUCCESS });
              }
            })


          });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }





        if (data) {
          localStorage.setItem("token", JSON.stringify(data.key));
          localStorage.setItem("username", username);

          
          
          axios
          .get(`/api/get_user_details/?username=`+localStorage.getItem("username"), {
            headers: { Authorization: `Token `+JSON.parse(localStorage.getItem("token"))  },
          })
          .then((res1) => {
            if(res1.data.role == 3){
              notification.open({
                message: 'Alert!',
                description: "Unable to login using the provided credentials.",
              });
            }else{
              localStorage.setItem("name", res1.data.first_name+" "+res1.data.last_name); 
              //CHECK IF DEFAULT PASSWORD
              axios.get(`api/check_default_password/?role=${ res1.data.role}&username=`+localStorage.getItem("username"), {
                headers: { Authorization: `Token `+JSON.parse(localStorage.getItem("token"))  },
              })
              .then((res2) => {
                if(res2.data.message == true){
                  localStorage.setItem("username", username);
                  dispatch({
                    type: FETCH_ERROR,
                    payload: res2.data.message,
                  });
                }
                else{
                  
                  if(res1.data.role === 5){
                    axios
                    .get(`/api/get_outlet_type_by_user_id/?user=${res1.data.id}`, {
                      headers: { Authorization: `Token `+JSON.parse(localStorage.getItem("token"))  },
                    })
                    .then((res) => {
                      // // console.log("Auth outlet type : "+res.data.type)
                      localStorage.setItem("type", res.data.type); 
                      
                    });
                  }
                  
                  localStorage.setItem("role", res1.data.role); 
                  
                  axios.defaults.headers.common["Authorization"] = "Bearer " + data.key;
      
                  dispatch({ type: USER_ROLE_SET, payload: res1.data.role });
                  dispatch({ type: USER_TOKEN_SET, payload: data.key });
                  dispatch({ type: FETCH_SUCCESS });
                }
              })
            }
          });

        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        dispatch({
          type: SHOW_MESSAGE,
          payload: error.response.data.non_field_errors,
        });
        notification.open({
          message: 'Alert!',
          description: error.response.data.non_field_errors,
        });
      });
  };
};

export const getUser = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("auth/me")
      .then(({ data }) => {
        // // console.log("userSignIn: ", data);
        if (data.result) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_DATA, payload: data.user });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        // // console.log("Error****:", error.message);
      });
  };
};

export const userSignOut = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });

    axios
      .post("api/logout/", {
        key: localStorage.getItem("token"),
      })
      .then(({ data }) => {
        // // console.log("log out", data);
        // if (data.result) {
        //   localStorage.removeItem("token");
        //   dispatch({type: FETCH_SUCCESS});
        //   dispatch({type: SIGNOUT_USER_SUCCESS});
        // } else {
        //   dispatch({type: FETCH_ERROR, payload: data.error});
        // }
        if (data) {
          localStorage.removeItem("token");
          localStorage.removeItem("role");
          localStorage.removeItem("username");
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: SIGNOUT_USER_SUCCESS });
          dispatch({ type: SIGNOUT_ROLE_SUCCESS });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        // // console.log("Error****:", error.message);
      });
  };
};
