import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./Dashboard'))}/>
      <Route path={`${match.url}users/kitchen`} component={asyncComponent(() => import('./Users/Kitchen'))}/>
      <Route path={`${match.url}ho/menu`} component={asyncComponent(() => import('./Menu/HO'))}/>
      <Route path={`${match.url}ho/pos`} component={asyncComponent(() => import('./Menu/POS'))}/>
      <Route path={`${match.url}ho/outlet-branch`} component={asyncComponent(() => import('./Menu/OutletBranch'))}/>
      <Route path={`${match.url}ho/kitchens`} component={asyncComponent(() => import('./Menu/Kitchen'))}/>
      <Route path={`${match.url}users/virtual-outlet`} component={asyncComponent(() => import('./Users/Virtual'))}/>
      <Route path={`${match.url}users/existing-outlet`} component={asyncComponent(() => import('./Users/Existing'))}/>
      <Route path={`${match.url}users/dispatcher`} component={asyncComponent(() => import('./Users/Dispatcher'))}/>
      <Route path={`${match.url}users/profile`} component={asyncComponent(() => import('./Users/Profile'))}/>

      <Route path={`${match.url}ho/menu-category`} component={asyncComponent(() => import('./MenuManagement/Category'))}/>
      <Route path={`${match.url}ho/menu-sub-category`} component={asyncComponent(() => import('./MenuManagement/SubCategory'))}/>

      <Route path={`${match.url}menu-settings/settings`} component={asyncComponent(() => import('./SystemManagement/Settings'))}/>
      <Route path={`${match.url}menu-settings/payment-gateway-config`} component={asyncComponent(() => import('./SystemManagement/PaymentConfig'))}/>
      <Route path={`${match.url}menu-settings/reports`} component={asyncComponent(() => import('./SystemManagement/Report'))}/>

      <Route path={`${match.url}dashboard-kitchen`} component={asyncComponent(() => import('./DashboardKitchen'))}/>
      <Route path={`${match.url}kitchen/orders`} component={asyncComponent(() => import('./Order/Kitchen'))}/>

      <Route path={`${match.url}dashboard-dispatcher`} component={asyncComponent(() => import('./DashboardKitchen'))}/>
      <Route path={`${match.url}dispatcher/orders`} component={asyncComponent(() => import('./Order/Dispatcher'))}/>

      <Route path={`${match.url}dashboard-outlet`} component={asyncComponent(() => import('./DashboardOutlet'))}/>
      
      <Route path={`${match.url}outlet/menu`} component={asyncComponent(() => import('./Menu/Virtual'))}/>
      <Route path={`${match.url}outlet/existing-menu`} component={asyncComponent(() => import('./Menu/Existing'))}/>
      
      <Route path={`${match.url}outlet/serving-schedule`} component={asyncComponent(() => import('./OutletManagement/ServingSchedule'))}/>
      <Route path={`${match.url}outlet/extras-category`} component={asyncComponent(() => import('./OutletManagement/ExtrasCategory'))}/>
      <Route path={`${match.url}outlet/extras`} component={asyncComponent(() => import('./OutletManagement/Extras'))}/>
      <Route path={`${match.url}outlet/config`} component={asyncComponent(() => import('./OutletManagement/Config'))}/>

    </Switch>
  </div>
);

export default App;
