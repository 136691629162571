import React, {useEffect,useState } from "react";
import {Button, Checkbox, Form, Input,notification,Row,Col} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";

import {userSignIn} from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";


const openNotification = () => {
  notification.open({
    message: 'Notification Title',
    description: 'This is the content of the Notification.',
  });
};

const SignIn = (props) => {
  const dispatch = useDispatch();
  const token = useSelector(({auth}) => auth.token);
  
  const onFinishFailed = errorInfo => {
    // // console.log('Failed:', errorInfo);
    openNotification();
  };

  const onFinish = values => {
    // // console.log("finish",values)
    dispatch(userSignIn(values));
  };

  useEffect(() => {
    if (token !== null) {
      props.history.push('/')
    }
  }, [token, props.history]);

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-login-content">
              {/* <img src="https://via.placeholder.com/272x395" alt='Neature'/> */}
            </div>
            
            {/* <h1>Logo or Background here!</h1> */}
            {/* <div className="gx-app-logo-wid">
              <h1><IntlMessages id="app.userAuth.signIn"/></h1>
              <p><IntlMessages id="app.userAuth.bySigning"/></p>
              <p><IntlMessages id="app.userAuth.getAccount"/></p>
            </div> */}
            <div className="gx-app-logo">
              <img alt="example" src={require("assets/images/logo.jpg")}/>
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0">

              <Form.Item
                // initialValue="hoadmin@kitchencity.com"
                rules={[{ required: true, message: 'The input is not valid E-mail!' }]} name="username">
                <Input placeholder="Email"/>
              </Form.Item>
              <Form.Item
                // initialValue="Pass@123456"
                rules= {[{required: true, message: 'Please input your Password!'}]}  name="password">
                <Input type="password" placeholder="Password"/>
              </Form.Item>
              <Form.Item>
                <Checkbox><IntlMessages id="appModule.iAccept"/></Checkbox>
                <span className="gx-signup-form-forgot gx-link"><IntlMessages
                  id="appModule.termAndCondition"/></span>
              </Form.Item>
              <Form.Item className="gx-text-right">
              <Row>
                <Col span={12} className="gx-text-left">
                  
                <h6>Powered By:</h6>
                  <img alt="example" style={{height:18}} src={require("assets/images/arclabs_logo.png")}/>
                </Col>
                <Col span={12}className="gx-text-right">
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signIn"/>
                </Button>
                </Col>
              </Row>
                
                {/* <span><IntlMessages id="app.userAuth.or"/></span> <Link to="/signup"><IntlMessages
                id="app.userAuth.signUp"/></Link> */}
              </Form.Item>
              <span
                className="gx-text-light gx-fs-sm"> 
                {/* demo user email: 'hoadmin@kitchencity.com' and password: 'Pass@123456' */}
                </span>
            </Form>
          </div>
          <InfoView/> 
          
        </div>
      </div>
    </div>
  );
};

export default SignIn;
