import React from "react";
import {useSelector} from "react-redux";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import { NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR, NAV_STYLE_NO_HEADER_MINI_SIDEBAR, THEME_TYPE_LITE } from "../../constants/ThemeSetting";

const SidebarContentKitchen = () => {
  let {navStyle, themeType} = useSelector(({settings}) => settings);
  let {pathname} = useSelector(({common}) => common);
  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];
  return (<>
      <SidebarLogo/>
      <div className="gx-sidebar-content">
        <div
        style={{marginTop:10}} 
        // className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          {/* <UserProfile/> */}
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">
            {/* <Menu.Item key="dashboard-kitchen">
              <Link to="/dashboard-kitchen">
                <i className="icon icon-dasbhoard"/>
                <span>
                  Dashboard
                  </span>
              </Link>
            </Menu.Item> */}
            <Menu.Item key="kitchen/orders">
              <Link to="/kitchen/orders">
                <i className="icon icon-avatar"/>
                <span>Orders</span>
              </Link>
            </Menu.Item>
            
            {/* <Menu.Item key="menu-settings/reports">
              <Link to="/menu-settings/reports">
                <i className="icon icon-modal"/>
                <span>Reports</span>
              </Link>
            </Menu.Item> */}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContentKitchen.propTypes = {};
export default SidebarContentKitchen;

