import React, { Component } from "react";
import { Row, Col, Form, Input, Button, Divider, Modal, Select, message,notification } from "antd";
import axios from "../util/Api";
import { withRouter } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import IntlMessages from "util/IntlMessages";

const Option = Select.Option;

class ChangeDefaultPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
      viewChangePassword: true,
      successAccept: false,
    };
    
  this.handleSuccessAccept = this.handleSuccessAccept.bind(this);
  }

  handleSuccessAccept = () => {
    this.setState({
      successAccept: true,
    });
  };

  handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  onToggleDefaultPassword = () =>{
    this.setState({
      viewChangePassword: !this.state.viewChangePassword
    })
    
  }

  success = () => {
    message.config({
      top: 65,
    });
    message
      .loading("Action in progress..", 2)
      .then(() => message.success("Password Successfully Changed! Please re-login to a new password.", 2.5));
  };

  fail = () => {
    message.config({
      top: 65,
    });
    message
      .loading("Action in progress..", 2)
      .then(() => message.error("Oops! Invalid form. Please try again.", 2.5));
  };

  handleFormSubmit = values => {

    const username = localStorage.getItem("username");

    this.setState({
      loadingButton: true
    })

    axios.get(`api/change_password/?email=${username}&old_password=${values.defaultPassword}&new_password=${values.newPassword}&confirm_password=${values.confirmPassword}`,{
      headers: { Authorization: `Token `+JSON.parse(localStorage.getItem("token"))  },
    })
    .then(res=>{
      // // console.log(res.data)
      
      this.setState({
        loadingButton: false
      })
      
      this.onToggleDefaultPassword()
      localStorage.removeItem("username");
        
      notification.open({
        message: 'Thank You!',
        description: "You have successfully changed your password.",
      });
    // this.setState({
    //   successAccept: true
    // });
      }
    )
  };

  render() {
    
    return (
      <Modal
      closable={false}
      title="Change Default Password"
      visible={this.state.viewChangePassword}
      footer={null}
      destroyOnClose
    >
    <Form
      onFinish={this.handleFormSubmit}
    >
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
        <Form.Item
            name="defaultPassword"
            label="Default Password"
            onKeyDown={this.handleKeyDown}
            rules={[
              {
                required: true,
                message: 'Please input your current password!',
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="newPassword"
            label="New Password"
            onKeyDown={this.handleKeyDown}
            rules={[
              {
                required: true,
                message: 'Please input your new password!',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  var reg= /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^])[A-Za-z\d@$!%*?&]{8,}$/
                  if (!value || reg.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Atleast 8 characters, one numeric, one lowercase, one uppercase, one special character and no spaces');
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label="Confirm Password"
            dependencies={['newPassword']}
            onKeyDown={this.handleKeyDown}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('The two passwords that you entered do not match!');
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <div layout="inline" className="gx-text-right">
        <Button
          key="reset"
          type="danger"
          ghost
          size="medium"
          onClick={this.onToggleDefaultPassword}
          ghost
        >
          Cancel
        </Button>
        <Button 
          htmlType="submit"
          type="primary" size="medium"
        loading={this.state.loadingButton}
        >
          Submit
        </Button>
      </div>
      
    </Form>
    
      {/* <SweetAlert
        show={this.state.successAccept}
        success
        title={<IntlMessages id="Accepted successful!" />}
        // onConfirm={this.props.toggleClose}
      ></SweetAlert> */}
    </Modal>
    );
  }
}

export default withRouter((ChangeDefaultPassword));
