import React from "react";
import {useSelector} from "react-redux";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

const SidebarContent = () => {

  let {navStyle, themeType} = useSelector(({settings}) => settings);
  let {pathname} = useSelector(({common}) => common);

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  const MenuItemGroup = Menu.ItemGroup;

  return (<>

      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div
        style={{marginTop:10}} 
        // className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          {/* <UserProfile/> */}
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">

            <Menu.Item key="dashboard">
              <Link to="/dashboard">
                <i className="icon icon-dasbhoard"/>
                <span>
                  Dashboard
                  </span>
              </Link>
            </Menu.Item>


            {/* <MenuItemGroup key="main" className="gx-menu-group" title="Menu Management"> */}
            <Menu.Item key="ho/menu">
              <Link to="/ho/menu">
                <i className="icon icon-select"/>
                <span>Master Menu</span>
              </Link>
            </Menu.Item>
            
            <Menu.Item key="ho/pos">
              <Link to="/ho/pos">
                <i className="icon icon-basic-calendar"/>
                <span>POS Records</span>
              </Link>
            </Menu.Item>

            <Menu.Item key="ho/outlet-branch">
              <Link to="/ho/outlet-branch">
                <i className="icon icon-modal"/>
                <span>Outlets</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="ho/kitchens">
              <Link to="/ho/kitchens">
                <i className="icon icon-modal"/>
                <span>Kitchens</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="menu-settings/reports">
              <Link to="/menu-settings/reports">
                <i className="icon icon-modal"/>
                <span>Reports</span>
              </Link>
            </Menu.Item>
                {/* <Menu.Item key="ho/virtual-menu">
                  <Link to="/ho/virtual-menu">
                    <i className="icon icon-avatar"/>
                    <span>Virtual Outlet Menu</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="ho/existing-menu">
                  <Link to="/ho/existing-menu">
                    <i className="icon icon-avatar"/>
                    <span>Existing Outlet Menu</span>
                  </Link>
                </Menu.Item> */}
            {/* </MenuItemGroup> */}

            <MenuItemGroup key="main" className="gx-menu-group" title="User Management">
                <Menu.Item key="users/kitchen">
                  <Link to="/users/kitchen">
                    <i className="icon icon-avatar"/>
                    <span>Kitchen</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="users/virtual-outlet">
                  <Link to="/users/virtual-outlet">
                    <i className="icon icon-avatar"/>
                    <span>Virtual Outlet</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="users/existing-outlet">
                  <Link to="/users/existing-outlet">
                    <i className="icon icon-avatar"/>
                    <span>Existing Outlet</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="users/dispatcher">
                  <Link to="/users/dispatcher">
                    <i className="icon icon-avatar"/>
                    <span>Dispatcher</span>
                  </Link>
                </Menu.Item>
                {/* <Menu.Item key="users/profile">
                  <Link to="/users/profile">
                    <i className="icon icon-avatar"/>
                    <span>Profile</span>
                  </Link>
                </Menu.Item> */}
            </MenuItemGroup>


            <MenuItemGroup key="main" className="gx-menu-group" title="Menu Management">
                <Menu.Item key="ho/menu-category">
                  <Link to="/ho/menu-category">
                    <i className="icon icon-all-contacts"/>
                    <span>Category</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="ho/menu-sub-category">
                  <Link to="/ho/menu-sub-category">
                    <i className="icon icon-all-contacts"/>
                    <span>Sub Category</span>
                  </Link>
                </Menu.Item>
              {/* </SubMenu> */}
            </MenuItemGroup>


            <MenuItemGroup key="main" className="gx-menu-group" title="System Management">
                {/* <Menu.Item key="menu-settings/settings">
                  <Link to="/menu-settings/settings">
                    <i className="icon icon-avatar"/>
                    <span>Settings</span>
                  </Link>
                </Menu.Item> */}
                <Menu.Item key="menu-settings/payment-gateway-config">
                  <Link to="/menu-settings/payment-gateway-config">
                    <i className="icon icon-setting"/>
                    <span>Payment Gateway Config</span>
                  </Link>
                </Menu.Item>
            </MenuItemGroup>
{/*             
            <MenuItemGroup key="main" className="gx-menu-group" title="System Management">
                <Menu.Item key="settings/user-status">
                  <Link to="/settings/user-status">
                    <i className="icon icon-profile2"/>
                    <span>User Status</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="settings/general-status">
                  <Link to="/settings/general-status">
                    <i className="icon icon-profile2"/>
                    <span>General Status</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="settings/roles">
                  <Link to="/settings/roles">
                    <i className="icon icon-avatar"/>
                    <span>Roles</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="settings/department">
                  <Link to="/settings/department">
                    <i className="icon icon-avatar"/>
                    <span>Departments</span>
                  </Link>
                </Menu.Item>
            </MenuItemGroup>
             */}
          
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};

export default SidebarContent;

