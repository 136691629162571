import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Drawer, Layout} from "antd";

import SidebarContent from "./SidebarContent";
import SidebarContentKitchen from "./SidebarContentKitchen";
import SidebarContentOutlet from "./SidebarContentOutlet";
import SidebarContentExistingOutlet from "./SidebarContentExistingOutlet";

import SidebarContentDispatcher from "./SidebarContentDispatcher";
import {toggleCollapsedSideNav, updateWindowWidth} from "appRedux/actions/Setting";

import axios from "../../util/Api";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";

const {Sider} = Layout;
const Sidebar = () => {

  let oType=null
  const dispatch = useDispatch();

  const {themeType, navStyle} = useSelector(({settings}) => settings);
  const { navCollapsed, width} = useSelector(({common}) => common);
  const {token, initURL,role} = useSelector(({auth}) => auth);

  const onToggleCollapsedNav = () => {
    dispatch(toggleCollapsedSideNav(!navCollapsed));
  };

  useEffect(() => {
    
    window.addEventListener('resize', () => {
      dispatch(updateWindowWidth(window.innerWidth));
      
    })
  }, [dispatch]);


  let drawerStyle = "gx-collapsed-sidebar";

  if (navStyle === NAV_STYLE_FIXED) {
    drawerStyle = "";
  } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
    drawerStyle = "gx-mini-sidebar gx-mini-custom-sidebar";
  } else if (navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
    drawerStyle = "gx-custom-sidebar"
  } else if (navStyle === NAV_STYLE_MINI_SIDEBAR) {
    drawerStyle = "gx-mini-sidebar";
  } else if (navStyle === NAV_STYLE_DRAWER) {
    drawerStyle = "gx-collapsed-sidebar"
  }
  if ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR
    || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) && width < TAB_SIZE) {
    drawerStyle = "gx-collapsed-sidebar"
  }
  // // console.log("token: "+ token +", "+"Role: "+role+", Role Local Storage: "+localStorage.getItem("role")+"Type: "+localStorage.getItem("type"))


  
  return (
    
      <Sider
      className={`gx-app-sidebar ${drawerStyle} ${themeType !== THEME_TYPE_LITE ? 'gx-layout-sider-dark' : null}`}
      trigger={null}
      collapsed={(width < TAB_SIZE ? false : navStyle === NAV_STYLE_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR)}
      theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
      collapsible>
      {
      }
      {
        navStyle === NAV_STYLE_DRAWER || width < TAB_SIZE ?
          <Drawer
            className={`gx-drawer-sidebar ${themeType !== THEME_TYPE_LITE ? 'gx-drawer-sidebar-dark' : null}`}
            placement="left"
            closable={false}
            onClose={onToggleCollapsedNav}
            visible={navCollapsed}>
              {
                role===1?
                <SidebarContent/>:
                role===4?
                <SidebarContentKitchen/>:
                role===5?
                localStorage.getItem("type")==="Virtual Cafe"?<SidebarContentOutlet/>:<SidebarContentExistingOutlet/>: 
                role===2?
                <SidebarContentDispatcher/>: 
                localStorage.getItem("role")==1?
                <SidebarContent/>:
                localStorage.getItem("role")==4?
                <SidebarContentKitchen/>:
                localStorage.getItem("role")==5?
                localStorage.getItem("type")==="Virtual Cafe"?<SidebarContentOutlet/>:<SidebarContentExistingOutlet/>: 
                localStorage.getItem("role")==2?
                <SidebarContentDispatcher/>: null
              }
          </Drawer> :
          role===1?
          <SidebarContent/>:
          role===4?
          <SidebarContentKitchen/>:
          role===5?
          localStorage.getItem("type")==="Virtual Cafe"?<SidebarContentOutlet/>:<SidebarContentExistingOutlet/>: 
          role===2?
          <SidebarContentDispatcher/>: 
          localStorage.getItem("role")==1?
          <SidebarContent/>:
          localStorage.getItem("role")==4?
          <SidebarContentKitchen/>:
          localStorage.getItem("role")==5?
          localStorage.getItem("type")==="Virtual Cafe"?<SidebarContentOutlet/>:<SidebarContentExistingOutlet/>: 
          localStorage.getItem("role")==2?
          <SidebarContentDispatcher/>: null
      }
    </Sider>
   )
};
export default Sidebar;
