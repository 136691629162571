import React from "react";
// import loader from "../../assets/images/loader.svg"
import loader from "../../assets/images/loader.gif"
import { RotateSpinner  } from "react-spinners-kit";

const CircularProgress = ({className}) => 
<div className={`loader ${className}`}>
  {/* <img src={loader} alt="loader"/> */}
  <RotateSpinner size={50} color="#686769" />
</div>;
export default CircularProgress;
