import React from "react";
import {useDispatch} from "react-redux";
import {Avatar, Popover,Row} from "antd";
import {userSignOut} from "../../appRedux/actions/Auth";

import {Link} from "react-router-dom";
const UserProfile = () => {
  const dispatch = useDispatch();
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li>
              <Link to="/users/profile">
                <span>
                  My Account
                  </span>
              </Link></li>
      <li onClick={() => dispatch(userSignOut())}>Logout
      </li>
    </ul>
  );

  return (
    // <div className="gx-flex-row gx-align-items-center gx-mb-2 gx-avatar-row">
      
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <Avatar src='https://via.placeholder.com/150x150'
                className="gx-size-35 gx-pointer gx-mr-3 gx-mb-1" alt=""/>
        <span className="gx-avatar-name">
          Welcome, {localStorage.getItem("name")}<i
          className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/>
          </span>
      </Popover>
    // </div>

  )
};

export default UserProfile;
