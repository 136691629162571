import React from "react";
import {useSelector} from "react-redux";
import {Menu,Input } from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";


const SidebarContentOutlet = () => {

  let {navStyle, themeType} = useSelector(({settings}) => settings);
  let {pathname} = useSelector(({common}) => common);

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  const MenuItemGroup = Menu.ItemGroup;

  return (<>

      <SidebarLogo/>
      <div className="gx-sidebar-content">
        <div
        style={{marginTop:10}} 
        // className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          {/* <UserProfile/> */}
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">

            {/* <Menu.Item key="dashboard-outlet">
              <Link to="/dashboard-outlet">
                <i className="icon icon-dasbhoard"/>
                <span>
                  Dashboard
                  </span>
              </Link>
            </Menu.Item> */}
            
            <Menu.Item key="outlet/existing-menu">
              <Link to="/outlet/existing-menu">
                <i className="icon icon-select"/>
                <span>Menu</span>
              </Link>
            </Menu.Item>
            
            
            <Menu.Item key="outlet/serving-schedule">
              <Link to="/outlet/serving-schedule">
                <i className="icon icon-select"/>
                <span>Serving Schedule</span>
              </Link>
            </Menu.Item>
            
            <Menu.Item key="outlet/extras-category">
              <Link to="/outlet/extras-category">
                <i className="icon icon-select"/>
                <span>Extras Category</span>
              </Link>
            </Menu.Item>
            
            <Menu.Item key="outlet/extras">
              <Link to="/outlet/extras">
                <i className="icon icon-select"/>
                <span>Extras</span>
              </Link>
            </Menu.Item>

            <Menu.Item key="outlet/config">
              <Link to="/outlet/config">
                <i className="icon icon-select"/>
                <span>Config</span>
              </Link>
            </Menu.Item>
            
            {/* <Menu.Item key="menu-settings/reports">
              <Link to="/menu-settings/reports">
                <i className="icon icon-modal"/>
                <span>Reports</span>
              </Link>
            </Menu.Item> */}
            
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContentOutlet.propTypes = {};

export default SidebarContentOutlet;

