import React, {useEffect} from 'react';
import CircularProgress from "components/CircularProgress/index";
import {message} from 'antd';
import Auxiliary from "util/Auxiliary";
import {connect} from "react-redux";
import {hideMessage} from "appRedux/actions/Common";
import {useDispatch, useSelector} from "react-redux";
import ChangeDefaultPassword from "../../containers/ChangeDefaultPassword";


const InfoView = () => {

  const dispatch = useDispatch();

  const error = useSelector(({commonData}) => commonData.error);

  const loading = useSelector(({commonData}) => commonData.loading);

  const displayMessage = useSelector(({commonData}) => commonData.error);

  // // console.log(JSON.stringify(displayMessage))

  useEffect(() => {
    if (error & error !== true || displayMessage & displayMessage !== true) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 3000);
    }
  }, [error, displayMessage, dispatch]);

  return (
    <Auxiliary>
      {loading && <div className="gx-loader-view gx-loader-position">
        <CircularProgress/>
      </div>}
      {displayMessage === true ? <ChangeDefaultPassword /> : displayMessage ? (message.error(displayMessage)): null}
    </Auxiliary>
  );
};

export default InfoView;

// class InfoView extends React.Component {
//   componentWillReceiveProps(nextProps) {
//     if (nextProps.error || nextProps.message) {
//       setTimeout(() => {
//         this.props.hideMessage();
//       }, 3000);
//     }
//   }

//   render() {
//     const {error, loading, displayMessage} = this.props;

//     // console.log("loading,", error);

//     return (
//       <Auxiliary>
//         {loading && <div className="gx-loader-view gx-loader-position">
//           <CircularProgress/>
//         </div>}
//         {/* {error && message.error(<span id="message-id">{error}</span>)} */}
//         {error===true ? <ChangeDefaultPassword/> :displayMessage && message.info(<span id="message-id">{displayMessage}</span>)}
//       </Auxiliary>
//     );
//   }
// }

// const mapStateToProps = ({common}) => {
//   const {error, loading} = common;
//   const displayMessage = common.message;
//   return {error, loading, displayMessage};
// };

// export default connect(mapStateToProps, {hideMessage})(InfoView);
